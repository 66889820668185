<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		
		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									OTOMASYON PROJESİ KURULUM
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)"/>
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="OTOMASYON PROJESİ KURULUM" :entityName="ENTITY_NAME"></EntityHeader>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bolge">Bölge</label>
								<Dropdown id="bolge" v-model="mainData.bm_bolge" :options="SM_bolge" optionLabel="Value" optionValue="AttributeValue" placeholder="Bölge" :showClear="true" :disabled="isDevreDisi" />
								<span v-if="v$.mainData.bm_bolge.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_bolge.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="sehir">Şehir <span style="color:red">*</span></label>
								<Dropdown id="sehir" v-model="mainData.bm_sehir" :options="SM_sehir" optionLabel="Value" optionValue="AttributeValue" placeholder="Şehir" :showClear="true" :filter="true" />
								<span v-if="v$.mainData.bm_sehir.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_sehir.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-6">
							<div class="field p-fluid">
								<label for="ekip">Ekip (Kaydedildikten sonra seçebilirsiniz)</label>
								<MultiSelect v-model="selectedMobilKullanicilar" :options="mobilKullanicilarData" optionLabel="name" placeholder="Müsaitlik sorgulaması için lütfen kaydediniz" :filter="true" class="multiselect-custom" :disabled="true">
								</MultiSelect>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bm_sevktarihi">Sevk Tarihi</label>
								<Calendar showButtonBar id="bm_sevktarihi" v-model="mainData.bm_sevktarihi" autocomplete="off" :showIcon="true" :disabled="isDevreDisi" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bm_baslangictarihi">Başlangıç Tarihi <span style="color:red">*</span></label>
								<Calendar showButtonBar id="bm_baslangictarihi" v-model="mainData.bm_baslangictarihi" autocomplete="off" :showIcon="true" :disabled="isDevreDisi" />
								<span v-if="v$.mainData.bm_baslangictarihi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_baslangictarihi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bm_bitistarihi">Bitiş Tarihi <span style="color:red">*</span></label>
								<Calendar showButtonBar id="bm_bitistarihi" v-model="mainData.bm_bitistarihi" autocomplete="off" :showIcon="true" :disabled="isDevreDisi" />
								<span v-if="v$.mainData.bm_bitistarihi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_bitistarihi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						
						<div class="col-12">
							<div class="field p-fluid">
								<label for="ozelnot">Özel Not</label>
								<Textarea id="ozelnot" rows="4" :autoResize="false" v-model="mainData.bm_ozelnot" />
							</div>
						</div>
						<div class="col-12">
							<div class="field p-fluid">
								<label for="yapilanlar">Yapılanlar</label>
								<Textarea id="yapilanlar" rows="3" :autoResize="false" v-model="mainData.bm_yapilanlar" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<br/>
		<Accordion :activeIndex="0">
			<AccordionTab>
				<template #header>
					<span style="font-size: 1.25rem; font-weight: 500;">ULAŞIM</span>
				</template>
				
				<div class="grid pt-3">
					<div class="col-3">
						<div class="field p-fluid">
							<label for="araba">Araba</label><br/>
							<InputSwitch id="araba" v-model="mainData.bm_araba" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="otobus">Otobüs</label><br/>
							<InputSwitch id="otobus" v-model="mainData.bm_otobus" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="tren">Tren</label><br/>
							<InputSwitch id="tren" v-model="mainData.bm_tren" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="ucak">Uçak</label><br/>
							<InputSwitch id="ucak" v-model="mainData.bm_ucak" :disabled="isDisabled" />
						</div>
					</div>
				</div>
			</AccordionTab>
		</Accordion>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 10056,
			ENTITY_NAME: 'bm_otomasyonkurulum',
			ENTITY_SUBJECT: 'Otomasyon Projesi',
			mainData: {},

			mobilKullanicilarData: [],
			selectedMobilKullanicilar: null,

			otomasyonAraclarData: [],
			selectedOtomasyonAraclar: null,

			nestedMenuitems: [
				{
					label:'Kaydet',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(false); },
				},
				{
					label:'Kaydet & Kapat',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(true); },
				},
			],
		}
	},
	async created() {
		this.crmService = new CrmService();
		console.log('created');

		window.addEventListener('scroll', this.handleScroll);
		this.OnLoad();
    },
	computed: {
		otomasyonProjesiId() {
			debugger;
			return this.$route.params.id;
		},
		profileData(){
			return this.$store.getters.getProfile;
		},
		SM_bolge: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_bolge');
		},
		SM_sehir: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_sehir');
		},
		SM_bm_ilerlemedurumu: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_ilerlemedurumu');
		},
	},
	methods: {
		async OnLoad(){
			let loader = this.$loading.show({
				container: this.$refs.smsContainer
			});

			debugger;
			if (this.otomasyonProjesiId != null) {
				try {
					debugger;

					let data = await this.crmService.getIlkKurulumKaydi(this.otomasyonProjesiId);
					if (data.jsonData.length > 0) {
						const ilkKurulumKaydi = data.jsonData[0];

						if (ilkKurulumKaydi["bm_bolge"]) {
							this.mainData["bm_bolge"] = ilkKurulumKaydi["bm_bolge"];
						}

						if (ilkKurulumKaydi["bm_sehir"]) {
							this.mainData["bm_sehir"] = ilkKurulumKaydi["bm_sehir"];
						}
					}
				} catch (error) {
					console.log(error);
					//this.$router.push('/');
				}
				finally {
					loader.hide();
				}
			}
			else {
				//this.$router.push('/');
				loader.hide();
			}
		},
		async OnSave(kapat) {
			debugger;
			
			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				let loader = this.$loading.show({
					container: this.$refs.smsContainer
				});

				try {
					if (this.selectedOtomasyonAraclar) {
						if (this.selectedOtomasyonAraclar.length > 0) {
							this.mainData["araclar"] = this.selectedOtomasyonAraclar;
						}
					}

					if (this.selectedMobilKullanicilar) {
						if (this.selectedMobilKullanicilar.length > 0) {
							this.mainData["ekip"] = this.selectedMobilKullanicilar;
						}
					}

					this.mainData["bm_otomasyonprojesiId"] = this.otomasyonProjesiId;
					const response = await this.crmService.getOtomasyonKurulumCreate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							
							if (kapat) {
								setTimeout(() => {
									loader.hide();
									window.close();
								}, 1000);
							}
							else {
								setTimeout(() => {
									loader.hide();
									this.$router.push({ name: this.ENTITY_NAME, params: { id: response.olusturulanId } });
								}, 1000);
							}
						}
					}
				} catch (error) {
					loader.hide();
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == "Otomasyon Projesi");
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				bm_bolge: {
					required : helpers.withMessage('Bölge alanı dolu olmalıdır!', required),
				},
				bm_sehir: {
					required : helpers.withMessage('Şehir alanı dolu olmalıdır!', required),
				},
				bm_baslangictarihi: {
					required : helpers.withMessage('Başlangıç alanı dolu olmalıdır!', required),
				},
				bm_bitistarihi: {
					required : helpers.withMessage('Bitiş Tarihi alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>
.stickBar {
	position: sticky;
    top: 6.2rem;
    z-index: 10;
}

.country-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

::v-deep(.multiselect-custom) {
	.p-multiselect-label:not(.p-placeholder) {
		padding-top: .50rem;
		padding-bottom: .50rem;
	}

	.country-item-value {
		padding: .25rem .5rem;
		border-radius: 3px;
		display: inline-flex;
		margin-right: .5rem;
		background-color: var(--primary-color);
		color: var(--primary-color-text);

		img.flag {
			width: 17px;
		}
	}
}

@media screen and (max-width: 640px) {
	.p-multiselect {
		width: 100%;
	}
}
</style>
